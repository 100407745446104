



import React, { useEffect, useState } from "react";
import CustomModal from '../components/modal/customModal';
import { Link } from 'react-router-dom'
const Home = () => {

    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            {/* <!--  Banner Section --> */}
            <div className="bannerMain">
                <div className="container">
                    <h4>Building Dreams</h4>
                    <h1>Engineered for <br />Excellence</h1>
                    <p>At <strong>SriStar Constructions</strong>, we turn your visions into reality by<br /> delivering world-class
                        construction solutions.</p>
                    <button className="bannerBtn" onClick={() => setModalShow(true)}>Contact Us<img className="img-fluid" src={require(`../assets/images/home/call.svg`).default} /></button>
                </div>
            </div>
            <>
                <CustomModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </>
            {/* <!-- Services Section --> */}
            <section className="our-service-section ">
                <div className="container">
                    <div className="ourService">
                        <div className="sectionHeadings">
                            <h3>● Our Services</h3>
                            <div className="subHedng">
                                <h2>Personalized Building
                                    <br /> Solutions
                                </h2>
                                <Link to="/packagesPlans">Start Building<img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default} /></Link>
                            </div>
                        </div>
                        <div className="serviceSlider">
                            <div className="serviceGrid">
                                <div className="img-srvc">
                                    <img className="img-fluid" src={require(`../assets/images/home/NewConstructions.jpg`)} />
                                </div>
                                <div className="srvcCount">
                                    <p><strong>New Constructions</strong></p>
                                </div>
                            </div>
                            <div className="serviceGrid">
                                <div className="img-srvc">
                                    <img className="img-fluid" src={require(`../assets/images/home/Renovations.jpg`)} />
                                </div>
                                <div className="srvcCount">
                                    <p><strong>Renovations</strong></p>
                                </div>
                            </div>
                            <div className="serviceGrid">
                                <div className="img-srvc">
                                    <img className="img-fluid" src={require(`../assets/images/home/Painting.jpg`)} />
                                </div>
                                <div className="srvcCount">
                                    <p><strong>Painting</strong></p>
                                </div>
                            </div>
                            <div className="serviceGrid">
                                <div className="img-srvc">
                                    <img className="img-fluid" src={require(`../assets/images/home/Interiors.jpg`)} />
                                </div>
                                <div className="srvcCount">
                                    <p><strong>Interiors</strong></p>
                                </div>
                            </div>
                            <div className="serviceGrid">
                                <div className="img-srvc">
                                    <img className="img-fluid" src={require(`../assets/images/home/Lift.jpg`)} />
                                </div>
                                <div className="srvcCount">
                                    <p><strong>Lifts</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Plans Section --> */}
            <section className="planSection section-gray ">
                <div className="container">
                    <div className="plnContainer">
                        <div className="pln-left">
                            <div className="sectionHeadings">
                                <h3>● Our Plans</h3>
                                <h2>Choose What Suits
                                    <br /> You Best
                                </h2>
                                <p>We offer three types of Packages namely BuildSimple, BuildSmart and ConstructEasy. And a BOQ Mode of
                                    Payment.</p>
                            </div>
                        </div>
                        <div className="pln-right">
                            <div className="pkg-grids">
                                <div className="pkg-card">
                                    <h5>PACKAGE PLAN</h5>
                                    <p>We offer three types of Preset Packages namely BuildSimple, BuildSmart and ConstructEasy. </p>
                                    <Link to="/packagesPlans">Know More <img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default} /></Link>
                                </div>
                                <div className="pkg-card">
                                    <h5>SUPER SMART PLAN</h5>
                                    <p>We offer you a plan where you can customize your budget, materials, costs and more. </p>
                                    <Link to="/superSmartPlan">Know More<img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default} /></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- About Us Section --> */}
            <section className="aboutUs-section">
                <div className="container">
                    <div className="aboutUsContent">
                        <div className="abtUsLeft">
                            <div className="abtLftContent">
                                <h3>About Us</h3>
                                <p>SriStar Group is a dynamic and diversified company with a strong presence across various sectors like
                                    construction, gold, media, hotel. We're driven by a commitment to quality, integrity, and innovation, with
                                    a clear mission to provide outstanding value to our customers, partners, and the communities we serve.</p>
                            </div>
                            <ul>
                                <li>
                                    <p><span>Our History</span></p>
                                </li>
                                <li>
                                    <p><span>Our Businesses</span></p>
                                </li>
                                <li>
                                    <p><span>Our Values</span></p>
                                </li>
                                <li>
                                    <p><span>Our Leadership</span></p>
                                </li>
                                <li>
                                    <p><span>Our Vision</span></p>
                                </li>
                            </ul>
                            <Link to="/superSmartPlan">Know More<img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default} /></Link>
                        </div>
                        <div className="abtRight">
                            <div className="grid-collage">
                                <div className="GridView1 fr-grdImg"><img className="img-fluid" src={require(`../assets/images/home/gridImg.png`)} /></div>
                                <div className="GridView2 fr-grdImg"><img className="img-fluid" src={require(`../assets/images/home/gridImg.png`)} /></div>
                                <div className="GridView3 fr-grdImg"><img className="img-fluid" src={require(`../assets/images/home/gridImg.png`)} /></div>
                                <div className="GridView4 fr-grdImg"><img className="img-fluid" src={require(`../assets/images/home/gridImg.png`)} /></div>
                            </div>
                        </div>
                    </div>
                </div>

            </section >

            {/* < !--Projects Section-- > */}
            <section className=" projectsSection ">
                <div className="container">
                    <div className="sectionHeadings">
                        <h3>● Our Projects</h3>
                        <div className="subHedng">
                            <h2>Transforming Urban
                                <br /> Landscapes
                            </h2>
                            <div className="decoSection">
                                <p>Our expert team ensures every project we take on is built to perfection. From residential to commercial
                                    developments, we provide tailored solutions</p>
                                {/* <div> x </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="orProjContent">
                        <div className="projectCards">
                            <div className="leftImgPrj">
                                <img className="img-fluid" src={require(`../assets/images/home/plot.png`)} />
                            </div>
                            <div className="projRightSection">
                                <h4>Abigere</h4>
                                <div className="gridPrjcts">
                                    <div className="prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/land_area.svg`).default} />
                                        <p>600 sq ft</p>
                                        <span>Land Area</span>
                                    </div>
                                    <div className="prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/built_up_area.svg`).default} />
                                        <p>1800 sq ft</p>
                                        <span>Built-Up Area</span>
                                    </div>
                                    <div className="prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/city.svg`).default} />
                                        <p>Bangalore</p>
                                        <span>City</span>
                                    </div>
                                    <div className="prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/budget.svg`).default} />
                                        <p>₹ 27,00,000</p>
                                        <span>Budget</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="projectCards">
                            <div className="leftImgPrj">
                                <img className="img-fluid" src={require(`../assets/images/home/site2.jpg`)} />
                            </div>
                            <div className="projRightSection">
                                <h4>MS Palya Aditya Nagar</h4>
                                <div className="gridPrjcts">
                                    <div className="prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/land_area.svg`).default} />
                                        <p>1980 sq ft</p>
                                        <span>Land Area</span>
                                    </div>
                                    <div className="prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/built_up_area.svg`).default} />
                                        <p>6100 sq ft</p>
                                        <span>Built-Up Area</span>
                                    </div>
                                    <div className="prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/city.svg`).default} />
                                        <p>Bangalore</p>
                                        <span>City</span>
                                    </div>
                                    <div className="prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/budget.svg`).default} />
                                        <p>₹ 1.25 CR</p>
                                        <span>Budget</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* < !--SubFooter Section-- > */}
            <section className="subFooter">
                <div className="container">
                    <div className="subFooterConent">
                        <div className="sbFootContent">
                            <h3>Like What <br />You See?</h3>
                            <p> Reach out to us and we are sure to provide you <br />with the best services.</p>
                            <Link to="/superSmartPlan">Know More<img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default} /></Link>
                        </div>
                        <div className="sbFootImg">
                            <img className="img-fluid" src={require(`../assets/images/Illustrations/cozyBed.png`)} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
import React, { useEffect, useState } from "react";
import packagePlanImage from '../assets/images/home/TopBanners/PackagePlan.png';
import { Link } from "react-router-dom";

const PackagePlain = () => {
    const [fullName, setFullName] = useState('');
    const [plotArea, setPlotArea] = useState('');
    const [plot_location, setPlotLocation] = useState('');
    const [setback, setSetBack] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [numberOfFloors, setNumberOfFloors] = useState(1);
    const [costing, setCosting] = useState('');


    const handleCalculation = async (e) => {
        e.preventDefault();
        console.log("Submitted");
        console.log(numberOfFloors);
        console.log(plotArea);
        console.log(setback);
        let calculatedCost = (plotArea * (100 - setback)) * numberOfFloors * 1939
        setCosting(calculatedCost);
    };
    const [activeSection, setActiveSection] = useState(null);

    const toggleSection = (section) => {
        setActiveSection(activeSection === section ? null : section);
    }

    return (
        <>
            {/* <!-- Inner Banner Section --> */}
            <section className="innerPageMain" style={{ backgroundImage: `url(${packagePlanImage})` }}>

                <div className="container">
                    <div className="topbnnerContent">
                        <div className="InnersectionHeadings">
                            <h3>● Our Pre-set Packages</h3>
                            <h2>Choose What Suits
                                <br /> You Best
                            </h2>
                            <p>We offer three types of Packages namely BuildSimple, BuildSmart <br />and ConstructEasy. And a
                                BOQ Plan</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--  Package Modal Section --> */}
            <section className="outPkgModalSection">
                <div className="container">
                    <div className="sectionHeadings">
                        <h3>● Our Package Plans</h3>
                        <div className="subHedng">
                            <h2>Compare Our
                                <br /> Plans
                            </h2>
                            <div className="decoSection">
                                <p>We offer three types of Packages namely BuildSimple, BuildSmart and ConstructEasy. And a BOQ
                                    Mode of Payment.</p>
                                <button className="borderLess">To Know About SuperSmart Model <img className="img-fluid"
                                    src={require(`../assets/images/home/redirct.svg`).default} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="plnPkgCont">
                        <div className="plnPkgGrid">
                            <div className="pkgHead">
                                <h4>Package 1</h4>
                                <div className="pkGoe">
                                    <p>BuildSimple</p>
                                    <span>1796/sq.ft (Incl.GST)</span>
                                </div>
                            </div>
                            <div className="pkgListing">
                                <div>
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('designs-drawings')}>
                                            Designs & Drawings <span>{activeSection === 'designs-drawings' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'designs-drawings' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Architectural Layout | 2D</li>
                                                <li>Basic Elevation</li>
                                                <li>Structural Design</li>
                                                <li>3D Elevation</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('structure')}>
                                            Structure <span>{activeSection === 'structure' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'structure' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Ceiling Height - 10 feet (Finished Floor level to Finished Floor level)</li>
                                                <li>Steel - Prime gold/ Meenakshi/Sunvik or Prime Gold or Kamadhenu or equivalent</li>
                                                <li>Cement - Dalmia, Bharathi or equivalent of 43 or 53 grade</li>
                                                <li>Aggregates - 20mm & 40mm</li>
                                                <li>Blocks - Standard Solid Concrete blocks. 6 inch & 4 inch (APCO/ AVS/ Equivalent)</li>
                                                <li>RCC Design Mix - M20 / M25 or As per the structural designer recommendation</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('kitchen')}>
                                            Kitchen <span>{activeSection === 'kitchen' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'kitchen' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Kitchen Counter Top – Granite @ ₹120/sq.ft</li>
                                                <li>Ceramic wall tiles - 2 ft. above Kitchen Slab (Upto ₹40/sq.ft)</li>
                                                <li>Main Sink Faucet - Upto ₹1,300</li>
                                                <li>Any other Faucet or Accessories - ISI Marked</li>
                                                <li>Kitchen Sink - Stainless Steel of Single Sink make worth ₹3,000</li>
                                                <li>Provision of Exhaust Fan is included. Exhaust fan fixture is not included</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('bathroom')}>
                                            Bathroom <span>{activeSection === 'bathroom' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'bathroom' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Ceramic Wall Dado upto 7' height - Upto ₹40/sq.ft</li>
                                                <li>Sanitarywares & CP fittings upto ₹30,000 per 1000 sq.ft of Cera make</li>
                                                <li>CPVC Pipe - APL Apollo or Equivalent</li>
                                                <li>Bathroom doors - Waterproof flush doors or WPC</li>
                                                <li>Provision of Exhaust Fan is included. Exhaust fan fixture is not included</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('doors-windows')}>
                                            Doors & Windows <span>{activeSection === 'doors-windows' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'doors-windows' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Main Door - Flush Door with Veneer. Sal wood frame of 5 inch by 3 inch, worth ₹20,000 including fixtures.</li>
                                                <li>Internal Doors - Membrane doors / Flush Door with Laminates upto ₹11,000 including fixtures. Door Frames of Sal Wood 4 inch by 2.5 inch.</li>
                                                <li>Windows - UPVC Windows with glass and mesh shutters (3 track with 1 mesh) of Lesso Eiti or equivalent make</li>
                                                <li>NOTE: Windows will be provided 10% of Total SQFT BUA of the home</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('painting')}>
                                            Painting <span>{activeSection === 'painting' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'painting' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Interior Painting - JK Putty + Tractor Shyne Emulsion or equivalent</li>
                                                <li>Exterior Painting - Asian Primer + Ace Exterior Emulsion Paint or equivalent</li>
                                                <li>Waterproofing is included for Utility & balcony portions not included</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('flooring')}>
                                            Flooring <span>{activeSection === 'flooring' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'flooring' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Living & Dining Flooring - Tiles of value upto ₹50 per sq.ft</li>
                                                <li>Rooms & Kitchen Flooring - Tiles of value upto ₹50 per sq.ft</li>
                                                <li>Balcony and Open Areas Flooring - Anti-skid tiles of value upto ₹40 per sq.ft</li>
                                                <li>Staircase Flooring - Sadarahalli Granite of value upto ₹70 per sq.ft</li>
                                                <li>Bathroom tiles - Anti-skid tiles (Upto ₹45/sq.ft)</li>
                                                <li>Parking Tiles - Anti-skid tiles of value upto ₹40 per sq.ft</li>
                                                <li>Tile grouting with spacer joint & with epoxy grout in bathrooms</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('electrical')}>
                                            Electrical <span>{activeSection === 'electrical' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'electrical' ? 'open' : ''}`}>
                                            <ul>
                                                <li>All wiring shall be done with fire proof wires of Finolex silver FR or equivalent.</li>
                                                <li>Switches & Sockets - Legrand Allzy or equivalent</li>
                                                <li>Electrical Fixtures – Not included</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('plumbing')}>
                                            Plumbing <span>{activeSection === 'plumbing' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'plumbing' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Water supply system – Internal & External</li>
                                                <li>Connection for water inlet from corporation</li>
                                                <li>External sewage system – Inspection chambers & sewage outlet to site boundary</li>
                                                <li>Class B CPVC pipes – Astral/Supreme make</li>
                                                <li>Solar & Geyser Provision</li>
                                                <li>Rain water harvesting facility – Ground water recharge</li>
                                                <li>PVC Overhead Tank 1000 Ltrs– Ganga or Equivalent make</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('miscellaneous')}>
                                            Miscellaneous <span>{activeSection === 'miscellaneous' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'miscellaneous' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Underground Sump - 4000 Ltrs</li>
                                                <li>Home automation – Not included</li>
                                                <li>Parapet wall construction: 3-foot high, 150mm thick blockwork, with buttresses at 10-foot intervals</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('fabrication')}>
                                            Fabrication <span>{activeSection === 'fabrication' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'fabrication' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Main Gate: MS Gate – ₹330/- per sq.ft</li>
                                                <li>External staircase & balcony railing : MS Railing – ₹180/- per Rft</li>
                                                <li>Internal staircase railing : SS Railing – ₹140/- per Rft</li>
                                                <li>Utility grill is not included in the package</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('elevation')}>
                                            Elevation <span>{activeSection === 'elevation' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'elevation' ? 'open' : ''}`}>
                                            <ul>
                                                <li>This proposal includes basic elevation design for your home</li>
                                                <li>A 3D elevation is presented for client to choose from & design</li>
                                                <li>Elevation can be designed & more elements may be added at very nominal cost to the design</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('exclusions')}>
                                            Exclusions & Incidentals <span>{activeSection === 'exclusions' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'exclusions' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Government fees: The client is responsible for paying government fees directly.</li>
                                                <li>Plan sanction fees: The client bears the complete fee payment for plan sanction in Panchayat limits.</li>
                                                <li>Site preparation:</li>
                                                <ul>
                                                    <li>Raising the ground level above 18" will incur additional charges.</li>
                                                    <li>Hard rock/soft rock excavation will be charged accordingly.</li>
                                                    <li>Earthwork (excavation, backfilling, or buying new earth) will be charged on actual costs.</li>
                                                </ul>
                                                <li>Material prices:</li>
                                                <ul>
                                                    <li>Cement: ₹350/- per bag (any price increase will be charged accordingly).</li>
                                                    <li>Steel: ₹50,000/- per MT (any price increase will be charged accordingly).</li>
                                                </ul>
                                                <li>Additional labour charges:</li>
                                                <ul>
                                                    <li>Cutting, laying, polishing, nosing, and chamfering of granite</li>
                                                    <li>Laying tiles larger than 2ft x 4ft</li>
                                                    <li>Laying Indian or Italian marble (up to ₹70 per sq.ft)</li>
                                                    <li>Buffing or chamfering/nosing (additional ₹30 per running ft)</li>
                                                    <li>Chemical treatment for marble (₹30 per sq.ft)</li>
                                                    <li>RCC underground sump: ₹18 per liter</li>
                                                    <li>Overhead tank: ₹9 per liter</li>
                                                    <li>Blockwork underground sump: ₹9 per liter</li>
                                                    <li>Septic tank Blockwork: ₹13 per liter</li>
                                                    <li>Septic tank Concrete: ₹23 per liter</li>
                                                </ul>
                                                <li>Design and construction:</li>
                                                <ul>
                                                    <li>Maximum percentage of opening for doors and windows: 12%.</li>
                                                    <li>Any work not mentioned in the package or outside the site boundary will incur additional charges.</li>
                                                </ul>
                                                <li>Client responsibilities:</li>
                                                <ul>
                                                    <li>Provide one point of electricity and water free of cost.</li>
                                                    <li>Offer a specific area for constructing labor and material sheds within 50 feet from the construction site.</li>
                                                </ul>
                                                <li>Additional charges:</li>
                                                <ul>
                                                    <li>Road cutting charges for electricity and sewage line connections.</li>
                                                    <li>Constraints in material delivery or storage may incur additional charges.</li>
                                                </ul>
                                                <li>Assumptions:</li>
                                                <ul>
                                                    <li>Site and road level are assumed to be equal.</li>
                                                    <li>SBC(Bearing capacity) of soil is considered at 180 KN/M2, and the depth of the foundation is assumed at 5 Feet (any increase in depth will incur additional charges).</li>
                                                </ul>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('payment')}>
                                            Payment Structure <span>{activeSection === 'payment' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'payment' ? 'open' : ''}`}>
                                            <ul>
                                                <li>A customized payment schedule tailored to the project's specific needs and milestones can help ensure a smooth and transparent financial process for both the client and the construction company.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('warranty')}>
                                            Warranty and Guarantee <span>{activeSection === 'warranty' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'warranty' ? 'open' : ''}`}>
                                            <ul>
                                                <li>In addition to the manufacturer's guarantee, the construction company is offering a 2-year workmanship warranty. This means that the company is committed to ensuring that the work done is of high quality and will last for at least 2 years.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="plnPkgGrid">
                            <div className="pkgHead pkg2">
                                <h4>Package 2</h4>
                                <div className="pkGoe">
                                    <p>BuildSmart</p>
                                    <span>1939/sq.ft (Incl.GST)</span>
                                </div>
                            </div>
                            <div className="pkgListing">

                                <div>
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('designs-drawings')}>
                                            Designs & Drawings <span>{activeSection === 'designs-drawings' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'designs-drawings' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Architectural Layout | 2D</li>
                                                <li>Basic Elevation</li>
                                                <li>Structural Design</li>
                                                <li>3D Elevation</li>
                                                <li>Drawings & Plans</li>
                                                <li>Electrical Drawings</li>
                                                <li>Plumbing Drawings</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('structure')}>
                                            Structure <span>{activeSection === 'structure' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'structure' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Ceiling Height: 10 feet (Finished Floor level to Finished Floor level)</li>
                                                <li>Steel: Bhuwalka/Kamadhenu equivalent</li>
                                                <li>Cement: Dalmia, Penna, or equivalent of 43 or 53 grade</li>
                                                <li>Aggregates: 20mm & 40mm</li>
                                                <li>Blocks: Standard Solid Concrete blocks (6 inch & 4 inch - APCO/AVS/Equivalent)</li>
                                                <li>RCC Design Mix: M20 / M25 or as per the structural designer recommendation</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('kitchen')}>
                                            Kitchen <span>{activeSection === 'kitchen' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'kitchen' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Kitchen Counter Top: Granite @ ₹140/sq.ft</li>
                                                <li>Ceramic wall tiles: 2 ft. above Kitchen Slab (Upto ₹60/sq.ft)</li>
                                                <li>Main Sink Faucet: Upto ₹2,000</li>
                                                <li>Any other Faucet or Accessories: ISI Marked</li>
                                                <li>Kitchen Sink: Stainless Steel Single Sink worth ₹6,000</li>
                                                <li>Provision for Exhaust Fan (fixture not included)</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('bathroom')}>
                                            Bathroom <span>{activeSection === 'bathroom' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'bathroom' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Ceramic Wall Dado up to 7' height (Upto ₹60/sq.ft)</li>
                                                <li>Sanitaryware & CP fittings: Upto ₹50,000 per 1,000 sq.ft (Hindware or Parryware make)</li>
                                                <li>CPVC Pipe: APL Apollo or Equivalent</li>
                                                <li>Bathroom doors: Waterproof flush doors or WPC</li>
                                                <li>Provision for Exhaust Fan (fixture not included)</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('doors-windows')}>
                                            Doors & Windows <span>{activeSection === 'doors-windows' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'doors-windows' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Main Door: Teak Door with Teak frame (5 inch by 3 inch), ₹30,000 including fixtures</li>
                                                <li>Internal Doors: Membrane doors/Flush Doors with Laminates (₹11,000 including fixtures)</li>
                                                <li>Windows: Aluminium Windows with glass shutters and mesh shutters (Jindal Profiles), 10% of Total BUA</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('painting')}>
                                            Painting <span>{activeSection === 'painting' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'painting' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Interior Painting: JK Putty + Tractor Shyne Emulsion or equivalent</li>
                                                <li>Exterior Painting: Asian Primer + Apex Exterior Emulsion Paint or equivalent</li>
                                                <li>Waterproofing: Included for Utility & Balcony (not for all areas)</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('flooring')}>
                                            Flooring <span>{activeSection === 'flooring' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'flooring' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Living & Dining: Tiles/Granite (up to ₹100/sq.ft)</li>
                                                <li>Rooms & Kitchen: Tiles (up to ₹80/sq.ft)</li>
                                                <li>Balcony & Open Areas: Anti-skid tiles (up to ₹60/sq.ft)</li>
                                                <li>Staircase: Sadarahalli Granite (up to ₹80/sq.ft)</li>
                                                <li>Bathroom Tiles: Anti-skid (up to ₹65/sq.ft)</li>
                                                <li>Parking Tiles: Anti-skid (up to ₹50/sq.ft)</li>
                                                <li>Tile grouting with spacer joint & epoxy grout in bathrooms</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('electrical')}>
                                            Electrical <span>{activeSection === 'electrical' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'electrical' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Wiring: Fireproof wires (Finolex Silver FR or equivalent)</li>
                                                <li>Switches & Sockets: Legrand Lyncus or Schneider Liva (or equivalent)</li>
                                                <li>UPS Wiring Provision</li>
                                                <li>Electric Car Charging Point: Parking Area</li>
                                                <li>Electrical Fixtures: Not included</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('plumbing')}>
                                            Plumbing <span>{activeSection === 'plumbing' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'plumbing' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Water Supply System: Internal & External</li>
                                                <li>Connection for water inlet from corporation</li>
                                                <li>External Sewage System: Inspection Chambers & Sewage Outlet</li>
                                                <li>Class B CPVC pipes (Astral/Supreme make)</li>
                                                <li>Solar & Geyser Provision</li>
                                                <li>Rainwater Harvesting Facility</li>
                                                <li>PVC Overhead Tank: 1500 Ltrs (Apollo or Equivalent make)</li>
                                                <li>Rainwater Harvesting Filter</li>
                                                <li>Separate Sewage & Drainage Lines</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('miscellaneous')}>
                                            Miscellaneous <span>{activeSection === 'miscellaneous' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'miscellaneous' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Underground Sump: 6000 Ltrs</li>
                                                <li>Home Automation: Not included</li>
                                                <li>Parapet Wall Construction: 3 feet high, 150mm thick blockwork</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('fabrication')}>
                                            Fabrication <span>{activeSection === 'fabrication' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'fabrication' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Main Gate: MS Gate – ₹450/sq.ft</li>
                                                <li>External Staircase & Balcony Railing: MS Railing – ₹220/ft</li>
                                                <li>Internal Staircase Railing: SS Railing – ₹180/ft</li>
                                                <li>Utility Grill: Not included in the package</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('elevation')}>
                                            Elevation <span>{activeSection === 'elevation' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'elevation' ? 'open' : ''}`}>
                                            <ul>
                                                <li>This proposal includes an aesthetically pleasing elevation</li>
                                                <li>A 3D elevation is presented for the client to choose from</li>
                                                <li>Elevation can be designed and modified at nominal cost</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('exclusions')}>
                                            Exclusions & Incidentals <span>{activeSection === 'exclusions' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'exclusions' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Government fees: Client responsible for payment</li>
                                                <li>Site preparation: Charges for raising ground level, excavation, or backfilling</li>
                                                <li>Material prices subject to change</li>
                                                <li>Client responsibilities: Provide electricity, water, and shed space</li>
                                                <li>Additional charges may apply for road cutting, material delivery, etc.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('payment')}>
                                            Payment Structure <span>{activeSection === 'payment' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'payment' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Customized payment schedule tailored to project milestones</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('warranty')}>
                                            Warranty and Guarantee <span>{activeSection === 'warranty' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'warranty' ? 'open' : ''}`}>
                                            <ul>
                                                <li>2-year workmanship warranty in addition to the manufacturer's guarantee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="plnPkgGrid">
                            <div className="pkgHead pkg3">
                                <h4>Package 3</h4>
                                <div className="pkGoe">
                                    <p>ConstructSimple</p>
                                    <span>2229/sq.ft (Incl.GST)</span>
                                </div>
                            </div>
                            <div className="pkgListing">
                                <div>
                                    {/* Designs & Drawings Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('designs-drawings')}>
                                            Designs & Drawings
                                        <span>{activeSection === 'designs-drawings' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'designs-drawings' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Architectural Layout | 2D</li>
                                                <li>Basic Elevation</li>
                                                <li>Structural Design</li>
                                                <li>Elevation & Visualization - 3D Basic Elevation</li>
                                                <li>Drawings & Plans</li>
                                                <li>Electrical Drawings</li>
                                                <li>Plumbing Drawings</li>
                                                <li>Furniture Plan</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Structure Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('structure')}>
                                            Structure
                                        <span>{activeSection === 'structure' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'structure' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Ceiling Height - 10 feet (Finished Floor level to Finished Floor level)</li>
                                                <li>Steel - Jindal or Indus or Tata equivalent</li>
                                                <li>Cement - ACC/Ultratech or equivalent of 43 or 53 grade</li>
                                                <li>Aggregates - 20mm & 40mm</li>
                                                <li>Blocks - Standard Solid Concrete blocks. 6 inch & 4 inch (Hydraulic Pressed Blocks)</li>
                                                <li>RCC Design Mix - M20 / M25 or As per the structural designer recommendation</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Kitchen Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('kitchen')}>
                                            Kitchen
                                        <span>{activeSection === 'kitchen' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'kitchen' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Kitchen Counter Top – Granite @ ₹150/sq.ft</li>
                                                <li>Ceramic wall tiles - 2 ft above Kitchen Slab (Upto ₹80/sqft)</li>
                                                <li>Main Sink Faucet - Upto Rs.3000</li>
                                                <li>Any other Faucet or Accessories - ISI Marked Jaguar, Paarryware, Hindware or equivalent</li>
                                                <li>Kitchen Sink - Stainless Steel of Single Sink OR Granite finish (Upto ₹8000)</li>
                                                <li>Provision of Exhaust Fan is included. Exhaust fan fixture is not included</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Bathroom Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('bathroom')}>
                                            Bathroom
                                        <span>{activeSection === 'bathroom' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'bathroom' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Ceramic Wall Dado upto 7' height - Upto Rs.80 per Sqft</li>
                                                <li>Sanitarywares & CP fittings upto Rs. 70,000 per 1000 Sqft of Hindware or Parryware make</li>
                                                <li>CPVC Pipe - APL Apollo or Equivalent</li>
                                                <li>Bathroom doors - Waterproof flush doors or WPC worth ₹12,000</li>
                                                <li>Provision of Exhaust Fan is included. Exhaust fan fixture is not included</li>
                                                <li>Mirror, soap dish, towel roll - Upto ₹6000 till 1000 sqft of package BuA</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Doors & Windows Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('doors-windows')}>
                                            Doors & Windows
                                        <span>{activeSection === 'doors-windows' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'doors-windows' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Main Door - Teak Door With Teak frame of 5 inch by 3 inch, worth Rs.45,000 including fixtures.</li>
                                                <li>Internal doors (includes Fixtures and Frame) - Sal frame with flush/membrane shutter (Upto ₹13,000 per 500 Sqft of the package BuA)</li>
                                                <li>Windows - Sal Frame Windows Grills of 150/sft with glass shutters and mesh shutters</li>
                                                <li>Pooja room door (Teak frame- of 5″ & 3″, teak shutter) – Upto ₹35,000 per 1000 Sqft of the package BuA inclusive of fixtures</li>
                                                <li>NOTE: Windows will be provided 10% of Total SQFT BUA of the home</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Painting Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('painting')}>
                                            Painting
                                        <span>{activeSection === 'painting' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'painting' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Interior Painting – JK Putty + Royale Luxury Emulsion</li>
                                                <li>Exterior Painting – Asian Primer + Apex Ultima Emulsion</li>
                                                <li>Waterproofing is included for Utility & balcony portions</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Flooring Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('flooring')}>
                                            Flooring
                                        <span>{activeSection === 'flooring' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'flooring' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Living and Dining flooring - Premium finished Vitrified Tiles/ Granite (Upto ₹140/Sqft)</li>
                                                <li>Rooms and Kitchen Flooring – Vitrified Tiles /Granite (Upto ₹120/sqft)</li>
                                                <li>Balcony and Open area flooring - Anti skid tiles (Upto ₹80/sqft)</li>
                                                <li>Staircase flooring - Sadrahalli Granite (Upto ₹110/Sqft)</li>
                                                <li>Bathroom tiles - Anti-skid tiles (Upto ₹80/sqft)</li>
                                                <li>Parking Tiles - Parking paver tiles (Upto ₹80/Sqft)</li>
                                                <li>Puja Room - Granite slab- Rs-100/- per Sqft 2nos</li>
                                                <li>Puja Room - Wall tiling – Full height – Rs 65 per Sqft</li>
                                                <li>Tile grouting with spacer joint & with epoxy grout in bathrooms</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Electrical Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('electrical')}>
                                            Electrical
                                        <span>{activeSection === 'electrical' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'electrical' ? 'open' : ''}`}>
                                            <ul>
                                                <li>All wiring shall be done with fire proof wires of Havells or equivalent.</li>
                                                <li>Switches & Sockets - GM Modular / Legrand or equivalent</li>
                                                <li>UPS Wiring Provision</li>
                                                <li>One Electric Car charging point – Parking area</li>
                                                <li>Wifi CCTV point</li>
                                                <li>Electrical Fixtures – Not included</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Plumbing Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('plumbing')}>
                                            Plumbing
                                        <span>{activeSection === 'plumbing' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'plumbing' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Water supply system – Internal & External</li>
                                                <li>Connection for water inlet from corporation</li>
                                                <li>External sewage system – Inspection chambers & sewage outlet to site boundary</li>
                                                <li>Class B CPVC pipes – Astral/Supreme make</li>
                                                <li>Solar & Geyser Provision</li>
                                                <li>Rain water harvesting facility – Ground water recharge</li>
                                                <li>PVC Overhead Tank – 2000 Ltrs Apollo or Equivalent make</li>
                                                <li>Rain Water Harvesting Filter</li>
                                                <li>Separate sewage & drainage lines</li>
                                                <li>Separate hot & cold water lines for sink</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Miscellaneous Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('miscellaneous')}>
                                            Miscellaneous
                                        <span>{activeSection === 'miscellaneous' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'miscellaneous' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Underground sump(built with solid blocks) - 7000 L (Can be changed in accordance to Client’s requirements)</li>
                                                <li>Home automation – Not included</li>
                                                <li>Parapet wall construction: 3-foot high, 150mm thick blockwork, with buttresses at 10-foot intervals</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Fabrication Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('fabrication')}>
                                            Fabrication
                                        <span>{activeSection === 'fabrication' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'fabrication' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Main Gate: MS Gate – Rs.450/- per Sqft</li>
                                                <li>External staircase & balcony railing: MS Railing – Rs. 350/- per Rft</li>
                                                <li>Internal staircase railing: SS Railing – Rs.400/- per Rft</li>
                                                <li>Utility grill is not included in the package</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Elevation Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('elevation')}>
                                            Elevation
                                        <span>{activeSection === 'elevation' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'elevation' ? 'open' : ''}`}>
                                            <ul>
                                                <li>This proposal includes a luxurious elevation</li>
                                                <li>A 3D elevation is presented for client to choose from & design</li>
                                                <li>Elevation can be designed & more elements may be added at a very nominal cost to the design</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Exclusions & Incidentals Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('exclusions')}>
                                            Exclusions & Incidentals
                                        <span>{activeSection === 'exclusions' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'exclusions' ? 'open' : ''}`}>
                                            <ul>
                                                <li>Government fees: The client is responsible for paying government fees directly.</li>
                                                <li>Plan sanction fees: The client bears the complete fee payment for plan sanction in Panchayat limits.</li>
                                                <li>Site preparation: Raising the ground level above 18" will incur additional charges.</li>
                                                <li>Material prices: Cement and Steel price increases will be charged accordingly.</li>
                                                <li>Additional labor charges for specific tasks (e.g., cutting, laying tiles, marble, etc.).</li>
                                                <li>Design and construction related charges.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Payment Structure Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('payment-structure')}>
                                            Payment Structure
                                        <span>{activeSection === 'payment-structure' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'payment-structure' ? 'open' : ''}`}>
                                            <ul>
                                                <li>A customized payment schedule tailored to the project's specific needs and milestones can help ensure a smooth and transparent financial process for both the client and the construction company.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Warranty and Guarantee Section */}
                                    <div className="accordion-item">
                                        <h2 onClick={() => toggleSection('warranty')}>
                                            Warranty and Guarantee
                                        <span>{activeSection === 'warranty' ? '-' : '+'}</span>
                                        </h2>
                                        <div className={`accordion-content ${activeSection === 'warranty' ? 'open' : ''}`}>
                                            <ul>
                                                <li>2-year workmanship warranty.</li>
                                                <li>Commitment to high-quality construction with a 2-year guarantee on the work done.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Get Estimate Section --> */}
            <section>
                <div className="container">
                    {/* <div className="sectionHeadings">
                        <h3>● Cost Estimator</h3>
                        <div className="subHedng">
                            <h2>Get An Estimate Of The
                                <br /> Construction Costs
                            </h2>
                            <div className="decoSection">
                                <p>Fill out the form below to get an estimation. Actual costs may vary depending on your city,
                                    talk to our Expert to get a more accurate pricing.</p>
                            </div>
                        </div>
                    </div>
                    <div className="getEstForm">
                        <form onSubmit={handleCalculation}>
                            <div className="innerGetsEstInp">
                                <div className="inputForm">
                                    <label>Name*</label>
                                    <input
                                        type='text'
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="inputForm">
                                    <label>Plot Area (sq.ft)*</label>
                                    <input
                                        type='number'
                                        value={plotArea}
                                        onChange={(e) => setPlotArea(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="inputForm">
                                    <label>Plot Location</label>
                                    <input
                                        type='text'
                                        value={plot_location}
                                        onChange={(e) => setPlotLocation(e.target.value)}
                                        required
                                        />
                                </div>
                                <div className="inputForm">
                                    <label>Setback % *</label>
                                    <input
                                        type='number'
                                        value={setback}
                                        onChange={(e) => setSetBack(e.target.value)}
                                        required
                                        />
                                </div>
                                <div className="inputForm">
                                    <label>Mobile Number</label>
                                    <input
                                        type='number'
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        required
                                        />
                                </div>
                                <div className="inputForm">
                                    <label>No. of Floors</label>
                                    <select placeholder='Floors' value={numberOfFloors} onChange={(e) => setNumberOfFloors(e.target.value)} required>
                                        <option value={1}>Ground</option>
                                        <option value={2}>G+1</option>
                                        <option value={3}>G+2</option>
                                        <option value={4}>G+3</option>
                                        <option value={5}>G+4</option>
                                    </select>
                                </div>
                                <div className="formikBtn">
                                    <button type='submit'>Get Estimate</button>
                                </div>
                                {
                                    costing ? (
                                        <>
                                            <p> Cost Estimate : Rs. {costing}.00</p>
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )
                                }
                            </div>
                        </form>
                    </div> */}
                    <div className="yourCostEstimate">
                        <h2 className="prHeading">Your Cost Estimate</h2>
                        <div className="estGridCont">
                            <div className="estCard pkg1">
                                <div className="estGrdHead">
                                    <h4>Package 1</h4>
                                    <div className="pkGoe">
                                        <p>BuildSimple</p>
                                        <span>1796/sq.ft (Incl.GST)</span>
                                    </div>
                                </div>
                                <div className="estGrdData">
                                    <ul>
                                        <li>
                                            <h4> BUILT UP COST
                                                <p>1070/sq.ft*1800/sq.ft</p>
                                            </h4>
                                            <span>26,00,000 </span>
                                        </li>
                                        <li>
                                            <h4>projected Cost
                                            </h4>
                                            <span>34,00,000</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="estCard">
                                <div className="estGrdHead pkg2">
                                    <h4>Package 2</h4>
                                    <div className="pkGoe">
                                        <p>BuildSmart</p>
                                        <span>1939/sq.ft (Incl.GST)</span>
                                    </div>
                                </div>
                                <div className="estGrdData">
                                    <ul>
                                        <li>
                                            <h4> BUILT UP COST
                                                <p>1070/sq.ft*1800/sq.ft</p>
                                            </h4>
                                            <span>26,00,000</span>
                                        </li>
                                        <li>
                                            <h4>projected Cost
                                            </h4>
                                            <span>34,00,000</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="estCard">
                                <div className="estGrdHead pkg3">
                                    <h4>Package 3</h4>
                                    <div className="pkGoe">
                                        <p>ConstructSimple</p>
                                        <span>2229/sq.ft (Incl.GST)</span>
                                    </div>
                                </div>
                                <div className="estGrdData">
                                    <ul>
                                        <li>
                                            <h4> BUILT UP COST
                                                <p>1070/sq.ft*1800/sq.ft</p>
                                            </h4>
                                            <span>26,00,000</span>
                                        </li>
                                        <li>
                                            <h4>projected Cost
                                            </h4>
                                            <span>34,00,000</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="estCard">
                                <div className="fullCardEst">
                                    <button>Check Out
                                        SuperSmart Plan <img className="img-fluid"
                                            src={require(`../assets/images/home/redirct.svg`).default} /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* < !--SubFooter Section-- > */}
            <section className="subFooter">
                <div className="container">
                    <div className="subFooterConent">
                        <div className="sbFootContent">
                            <h3>Like What <br />You See?</h3>
                            <p> Reach out to us and we are sure to provide you <br />with the best services.</p>
                            <Link to="/superSmartPlan">Know More<img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default} /></Link>
                        </div>
                        <div className="sbFootImg">
                            <img className="img-fluid" src={require(`../assets/images/Illustrations/cozyBed.png`)} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PackagePlain;